<template>
  <div class="body-m">

    <div class="wrapper">
      <div class="landing-page">
        <template v-if="!setting.error">
          <section class="l-bg-gray overflow-hidden min-vh-100" v-show="!finish">
            <div class="d-md-flex container mb-5">
              <div class="col-md-12 pl-0 pr-0">
                <template v-if="(step === 'OS' && !setting.atendimento)">
                  <div class="m-sem-bg p-4">
                    <img class="img-title01" src="/img/m/title-img.png" alt=""/>
                    <p class="text-center mt-4 m-p">
                      <template v-if="setting.unidade.diz_vc_texto1">
                        <div v-html="setting.unidade.diz_vc_texto1"></div>
                      </template>
                      <template v-else>
                        <strong class="m-title text-center">Para darmos continuidade à sua avaliação precisamos que informe
                          corretamente o número do celular
                          com DDD ou o número do atendimento (ordem de serviço).</strong>
                        <br/>Você encontrará essa informação em seu recibo de atendimento. <br>
                        <strong class="m-title text-center">O formato geralmente é
                          12X.XXXXXX</strong>
                      </template>
                    </p>
                  </div>

                  <img class="m-img-marker-left" src="/img/m/bolinhas-horiz.png" alt=""/>
                  <img class="m-img-marker-right" src="/img/m/bolinhas-vert.png" alt=""/>

                  <div class="d-md-block text-center m-2">
                    <input v-model="cliente"
                          class="m-input p-2"
                          placeholder="Nome completo (opcional)"
                          maxlength="40"
                          required/>
                  </div>

                  <div class="d-md-block text-center m-2">
                    <input v-model="phone" class="m-input p-2" placeholder="Celular (opcional)" required/>
                  </div>
                  <div class="d-md-block text-center m-2">
                    <input v-model="atendimento" class="m-input p-2" placeholder="Ordem de serviço" required/>
                  </div>
                  <div class="d-md-block text-center">
                    <a @click="avancarAposIdentificacao"
                      class="btn btn-primary m-btn mb-0">
                      Avançar
                    </a>
                  </div>
                  <div class="mt-4">
                    <a @click="avancarAposIdentificacao"
                      class="btn m-btn m-btn-secondary m-0 ml-2 mb-0">
                      Avaliar Anonimamente
                    </a>
                  </div>
                </template>

                <template id="grade" v-if="(step === 'grade' || setting.atendimento)">
                  <div class="sem-bg">
                    <div class="borda">
                      <img class="img-title" src="/img/m/title-img.png" alt=""/>


                      <img class="m-img-marker-left-inf" src="/img/m/bolinhas-hor2.png" alt=""/>
                      <img class="m-img-marker-right-inf" src="/img/m/bolinhas-hor2.png" alt=""/>
                      <p class="text-center mt-4 m-p"
                        v-if="setting.cliente">
                        <strong>Prezado(a) {{ setting.cliente }}</strong>
                      </p>
                      <p class="text-center mt-4 m-p">
                        <strong class="m-strong text-center">De 0 a 10, qual é a probabilidade de <u>você
                          recomendar</u> o(a) {{ setting.unidade.nome }} para um familiar ou amigo?</strong>
                        <br/>
                        <i class="m-italic">
                          Sendo <strong class="m-strong">0</strong> (nada provável) e <strong class="m-strong">10</strong>
                          (muito provável)
                        </i>
                      </p>

                      <ul class="actions special ">
                        <li class="box-numero d-flex" v-for="i in 11" @click="selecionarGrade(i-1)" :key="i">
                          <a class="m-btn numero">
                            {{ i - 1 }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </div>
            </div>

          </section>

          <section id="qualifiers" class="overflow-hidden a-bg-default mt-0"
            v-show="Number.isInteger(grade) && !finish">
            <Qualifiers 
              :grade="grade"
              @update:message="updateMessage"
              @finish:form="concluir"/>
          </section>

          <!-- <section id="qualifiers" class="container overflow-hidden a-bg-default mt-3"
                  v-show="Number.isInteger(grade) && !finish">
            <div class=" text-center note-content">
              <h2 class="m-title-note m-0">Nota {{ grade }}</h2>
            </div>

            <div class="d-md-flex pt-4 mb-5">
              <div class="col-md-6 col-sm-12 p-md-3">
                <header class="layout-m">
                  <h2 class="m-title-small m-0">Avalie sua experiência:</h2>
                </header>

                <div v-for="(qualification, radioIdx) of setting.qualifiers"
                    :key="`qualification-radio-${radioIdx}-${qualification.name}`">
                  <div class="container-toggle align-items-center">
                    <span>{{ qualification.name }}</span>
                    <div class="toggle">
                      <input type="radio" v-model="qualification.value" :name="[[qualification.name ]]" value="N"
                            :id="[[radioIdx]] + 'Like'" class="dislike"/>
                      <label :for="[[radioIdx]] + 'Like'" class="dislike">
                        <i
                            class="v-icon pl-1"
                            v-html="$MyIcons.icon( 'dislike-outline',  qualification.value === 'N' ? null: '#c02416e6')"/>
                      </label>
                      <input type="radio" v-model="qualification.value" :name="[[qualification.name ]]" value="P"
                            :id="[[radioIdx]] + 'Dislike'"/>
                      <label :for="[[radioIdx]] + 'Dislike'">
                        <i
                            class="v-icon pl-1"
                            v-html="$MyIcons.icon( 'like-outline', qualification.value === 'P' ? null: '#39a567')"/>
                      </label>
                    </div>
                  </div>
                  <div class="a-divider"/>
                </div>


              </div>
              <div class="col-md-6 col-sm-12 p-md-3">
                <header class="layout-m mb-2">
                  <h3 class="m-subtitle-small m-0">Agradecemos por contribuir com a nossa melhoria contínua!</h3>
                </header>

                <p>Caso tenha algo mais a dizer, gostaríamos de receber o seu comentário.</p>


                <div class="d-flex d-md-block form-group">
                    <textarea
                        v-model="message"
                        class="form-control a-text-area"
                        rows="3"></textarea>
                </div>

                <div class="d-md-block text-center">
                  <a @click="concluir()"
                    v-bind:style="{'borderColor': setting.unidade.cor}"
                    class="btn m-btn m-btn-primary">
                    Concluir
                  </a>
                </div>
              </div>
            </div>

          </section> -->

          <section class="a-bg overflow-hidden min-vh-80"
                  v-bind:class="{'v-bg': setting.layout ===  'v', 'a-bg': setting.layout ===  'a' }"
                  v-show="finish">
            <div class="d-md-flex container mb-5">
              <div class="col-md-12 pl-0 pr-0" style="text-align: center;">
                <img class="img-title01 mb-5 mt-5" :src="setting.unidade.diz_vc_logo" alt=""/>
                <p class="text-center mt-4 m-p">
                  <strong class="b-title">Agradecemos por enviar sua opinião!</strong>
                </p>
                <div v-if="this.grade > 6">
                  <p class="text-center mt-4 m-p">
                    <a v-if="setting.unidade.site" :href="setting.unidade.site" target="__blank" class="mr-3">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('web', '#5f5f5f', '60px', '60px')"/>
                    </a>
                    <a v-if="setting.unidade.instagram" :href="setting.unidade.instagram" target="__blank" class="mr-3">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('instagram', '#5f5f5f', '60px', '60px')"/>
                    </a>
                    <a v-if="setting.unidade.facebook" :href="setting.unidade.facebook" target="__blank" class="mr-3">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('facebook', '#5f5f5f', '60px', '60px')"/>
                    </a>
                    <a v-if="setting.unidade.twitter" :href="setting.unidade.twitter" target="__blank">
                      <i  
                          style="vertical-align: -3px;"
                          class="v-icon pl-1"
                          v-html="$MyIcons.icon('twitter', '#5f5f5f', '60px', '60px')"/>
                    </a>
                  </p>
                  <p class="text-center mt-4 m-p">
                    <strong class="a-title">Acompanhe nossas redes sociais</strong>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </template>

        <template v-else>
          <section class="a-bg overflow-hidden min-vh-80"
            v-show="!finish">
            <div class="d-md-flex container mb-5">
              <div class="col-md-12 pl-0 pr-0" style="text-align: center;">
                <img class="img-title01 mb-5 mt-5" :src="setting.unidade.diz_vc_logo" alt=""/>
                <p class="text-center mt-4 mb-5 m-p">
                  <strong v-if="!setting.hasOwnProperty('warning')" class="b-title">O seu formulário não está mais disponivel,</strong>
                  <strong v-if="setting.hasOwnProperty('warning')" class="b-title">Este formulário não está disponivel.</strong>
                  <br>
                  <strong v-if="!setting.hasOwnProperty('warning')" class="b-title">mas seu comentário nos ajudará a aperfeiçoar o atendimento.</strong>
                </p>
                <div v-if="!setting.hasOwnProperty('warning')" class="d-flex d-md-block form-group">
                    <textarea
                        placeholder="Comentário"
                        v-model="message"
                        class="form-control a-text-area text-area-bg"
                        rows="5"></textarea>
                </div>

                <div v-if="!setting.hasOwnProperty('warning')" class="d-md-block text-center">
                  <button :disabled="!message"
                    v-bind:style="{'borderColor': setting.unidade.cor}"
                    class="btn m-btn m-btn-primary"
                    @click="concluir()">
                    Concluir
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section class="a-bg overflow-hidden min-vh-80"
            v-show="finish">
            <div class="d-md-flex container mb-5">
              <div class="col-md-12 pl-0 pr-0" style="text-align: center;">
                <img class="img-title01 mb-5 mt-5" :src="setting.unidade.diz_vc_logo" alt=""/>
                <p class="text-center mt-4 mb-5 m-p">
                  <strong class="b-title">O seu comentário foi enviado com sucesso</strong>
                  <br>
                  <strong class="b-title">e será avaliado pela nossa equipe.</strong>
                </p>
              </div>
            </div>
          </section>
        </template>

      </div>
    </div>

    <MainFooter :background-color="'black'"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {Api} from "../../services/api";
import MainFooter from "./MainFooter";
import Qualifiers from "./../Qualifiers";
import moment from "moment";
import {ApiComunicante} from "@/services/apiComunicante";


export default {
  name: 'm-page',
  components: {MainFooter, Qualifiers},
  props: {
    preview: { type: Boolean, required: true },
  },
  data() {
    return {
      atendimento: null,
      cliente: null,
      phone: null,
      grade: null,
      message: null,
      year: null,
      finish: false,
      step: 'OS',
      timer: '',
      tempoInicio: '',
      tempoFinal: ''
    }
  },
  computed: {
    ...mapState({
      setting: state => state.app.configs,
    })
  },
  mounted() {
    this.tempoInicio = moment().format('YYYY-MM-DD HH:mm:ss')
    const nota = parseInt(this.$route.params.nota) ? parseInt(this.$route.params.nota) : undefined;
    if(nota !== undefined) {
      this.selecionarGrade(nota)
    }
  },
  methods: {
    selecionarGrade(grade) {
      if (grade < 0 || grade > 10) return;

      this.tempoFinal = moment().format('YYYY-MM-DD HH:mm:ss')
      ApiComunicante.enviarEstatistica({
          contaId: this.setting.conta_id,
          dataInicio: this.tempoInicio,
          dataFim: this.tempoFinal,
          uid: this.setting.uid,
          recursoTipoId: this.$route.name == 'Qr Code' ? 20 : 12,
          titulo: this.$route.name == 'Qr Code' ? 'Qr Code ' : 'Pesquisa',
          descricao: 'Layout ' + this.setting.layout.toUpperCase(),
          origemChamada: ''
        }
      )

      this.grade = grade

      const data = this.mountData()

      data.opine = this.$route.params.opine && this.$route.params.opine === 'opine';

      if (!data.opine) {
        if (this.$route.params.id && this.$route.params.hash) {
          Api.update(this.$route.params.id, this.$route.params.hash, data)
        } else if (this.$route.params.id && this.$route.params.setor) {
          Api.updateQrCode(this.$route.params.id, this.$route.params.setor, data)
        }
      }

      this.goToDiv('qualifiers')
    },
    avancarAposIdentificacao() {
      if(this.$route.name == 'Qr Code') {
        this.tempoFinal = moment().format('YYYY-MM-DD HH:mm:ss')
        ApiComunicante.enviarEstatistica({
            contaId: this.setting.conta_id,
            dataInicio: this.tempoInicio,
            dataFim: this.tempoFinal,
            recursoTipoId: 19,
            uid: this.setting.uid,
            titulo: 'Qr Code',
            descricao: 'Layout ' + this.setting.layout.toUpperCase(),
            origemChamada: ''
          }
        )
        this.tempoInicio = moment().format('YYYY-MM-DD HH:mm:ss')
      }
      this.step = 'grade'
    },
    async concluir() {

      if(this.preview === false){
        const data = this.mountData()

        data.opine = this.$route.params.opine && this.$route.params.opine === 'opine';

        this.finish = true

        if (this.$route.params.id && this.$route.params.hash) {
          await Api.update(this.$route.params.id, this.$route.params.hash, data)
        } else if (this.$route.params.id && this.$route.params.setor) {
          await Api.updateQrCode(this.$route.params.id, this.$route.params.setor, data)
          this.timer = setTimeout("window.location.reload()", 30000);
        }

      } else {
        this.finish = true
      }

      window.scrollTo(0, 0)
    },
    mountData() {
      return {
        grade: this.grade,
        cliente: this.cliente,
        atendimento: this.atendimento ? this.atendimento : this.setting.atendimento,
        phone: this.phone,
        message: this.message,
        qualifiers: this.setting.qualifiers,
        uuid: this.setting.uuid,
      }
    },
    goToDiv(div) {
      setTimeout(() => {
        var elmnt = document.getElementById(div);
        elmnt.scrollIntoView();
      }, 100)
    },
    updateMessage(message) {
      this.message = message
    }
  },
  beforeDestroy () {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
/*TODO baixar font e add no projeto*/
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,400,400italic");

.body-m {
  width: 100%;
  height: 100%;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  color: #666;
  background-color: #fff;
  font-size: 16px;
  overflow-x: hidden;
  font-weight: 300;
  line-height: 1.65em;
}


a.l-btn:hover {
  text-decoration: none;
}

.a-title {
  font-weight: 400;
  font-size: 1.2em;
}

.b-title {
  font-weight: 600;
  font-size: 1.5em;
}

a.m-btn:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.125);
}

.a-bg-default {
  font-size: 1.3em;
  line-height: 1.35em;
  color: #555;
}

.btn:disabled:hover {
  opacity: 0.65;
  color: #fff !important;
  border: none !important;
}

.m-btn {
  box-shadow: inset 0 0 0 1px #ffffff;
  color: #ffffff !important;
  font-size: 20px;
  border-radius: 32px;
  /*padding: 0px 25px;*/
  line-height: 53px;
  /*font-weight: bold;*/
  margin-bottom: 2.5vw;
  width: 40%;
  background: #ff4a00;
  padding: 0px 25px;
  line-height: 53px;
}

.m-btn:hover {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ff4a00 !important;
  color: #ff4a00 !important;
}

.m-btn:active {
  background-color: rgba(255, 255, 255, 0.8);
  color: #d04b38 !important;
}

.m-btn-primary {
  color: #fff !important;
}

.m-btn-primary:hover {
  color: #d04b38 !important;
  border: solid 1px rgba(255, 255, 255, 0.125);
}

.m-btn-secondary {
  color: #ff4a00 !important;
  border: solid 1px #ff4a00;
  background-color: transparent;
}

.m-btn-secondary:hover {
  color: #fff !important;
  background-color: #ff4a00 !important;
}


.l-bg-gray {
  padding: 0;
  color: rgba(255, 255, 255, 0.75);
  background: rgb(235, 235, 236);
  background: linear-gradient(
      0deg, rgba(235, 235, 236, 1) 40%, rgba(255, 255, 255, 1) 100%);
  overflow: hidden;
  position: relative;
  text-align: center;
  padding-bottom: 80px !important;
}

.m-img-marker-left {
  position: absolute;
  left: 50%;
  margin-left: -512px;
  margin-top: -10px;
}

.m-img-marker-right {
  position: absolute;
  right: 50%;
  margin-right: -420px;
  margin-top: -250px;
}

.m-img-marker-left-inf {
  position: absolute;
  left: 50%;
  margin-left: -512px;
  margin-top: -110px;
}

.m-img-marker-right-inf {
  position: absolute;
  right: 50%;
  margin-right: -512px;
  margin-top: 340px;
}

.m-strong {
  font-weight: 500;
  font-size: 1.2em;
}

.m-italic {
  font-style: italic;
}

.m-sem-bg {
  color: #555;
  width: 100%;
  margin: 0 auto;
  background-size: contain;
  max-width: 625px;
}

.m-input {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #ffffff !important;
  color: #4686a0 !important;
  border: none;
  border-radius: 36px;
  padding: 0;
  font-size: 1rem;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: normal;
  text-align: center;
  width: 40%;
}

.m-input::placeholder { /* Edge */
  color: #afafaf;
}

.m-input:hover {
  background-color: rgba(255, 255, 255, 0.125) !important;
}

.m-input:hover {
  background-color: rgba(255, 255, 255, 0.125) !important;
}

.m-input:focus, .m-input:focus {
  outline: none;
}

.m-p {
  font-size: 25px;
  line-height: 35px;
  color: #5f5f5f;
}

.m-title {
  color: #555;
  font-weight: 500;
  font-size: 25px;;
}


.sem-bg {
  width: 100%;
  margin: 0 auto;
  border-radius: 12px;
  background-size: contain;
  padding: 25px;
  max-width: 625px;
}

.sem-bg .borda {
  border: 3px solid #ff4a00;
  position: relative;
  margin-left: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 80px;
  margin-top: 120px;
  padding: 0 20px;
  padding-bottom: 15px;
}

.sem-bg .img-title {
  width: 100%;
  max-width: 554px;
  margin-top: -105px;
}

.borda:after {
  content: '';
  background: url("/img/m/borda-triangulo.jpg");
  width: 157px;
  height: 69px;
  position: absolute;
  left: 50%;
  margin-left: -69px;
  margin-top: 13px;
}

.m-title-note {
  font-size: 2em;
  line-height: 1.35em;
  color: #555;
  font-weight: 300;
}


header > .m-title-small {
  font-size: 1.5em;
  font-weight: 300;
}

header.layout-m:after {
  background: rgba(144, 144, 144, 0.5);
  content: '';
  display: inline-block;
  height: 1px;
  width: 6em;
}

header > .m-subtitle-small {
  font-size: 1.2em;
  line-height: 1.35em;
  color: #555;
  font-weight: 300;
}


.a-divider {
  display: block;
  width: 100%;
  height: 1px;
  margin: 10px auto;
  background: rgba(144, 144, 144, 0.5);
}


/* Actions */

.actions {
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  margin-top: 0em;
  margin-left: 0;
  position: relative;
  flex-wrap: wrap;
  -moz-transition: -moz-transform 1.25s ease;
  -webkit-transition: -webkit-transform 1.25s ease;
  -ms-transition: -ms-transform 1.25s ease;
  transition: transform 1.25s ease;
  -moz-transition-delay: 1s;
  -webkit-transition-delay: 1s;
  -ms-transition-delay: 1s;
  transition-delay: 1s;
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;

}

ul.actions {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  cursor: default;
  list-style: none;
  margin-left: -1em;
  padding-left: 0;
}

ul.actions li {
  padding: 0 0 0 1em;
  vertical-align: middle;
  /*margin-bottom: 1rem;*/
}

ul.actions li.box-numero {
  /*margin-bottom: 2.5vw;*/
  width: 33%;
}

ul.actions li .numero {
  width: 100%;
  font-size: 27px !important;
  border-radius: 50px !important;
  padding: 16px 23px !important;
  line-height: 41px !important;
}

ul.actions.special {
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 100%;
  margin-left: 0;
}

ul.actions.special li:first-child {
  /*padding-left: 0;*/
}

ul.actions.stacked {
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 0;
}

ul.actions.stacked li {
  padding: 1.3em 0 0 0;
}

ul.actions.stacked li:first-child {
  padding-top: 0;
}

ul.actions.fit {
  width: calc(100% + 1em);
}

ul.actions.fit li {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  width: 100%;
}

ul.actions.fit li > * {
  width: 100%;
}

ul.actions.fit.stacked {
  width: 100%;
}

ul.actions li.box-numero {
  /*margin-bottom: 2.5vw;*/
  width: 33%;
}


.a-text-area {
  border: solid 1px rgba(144, 144, 144, 0.8) !important;
  background: rgba(144, 144, 144, 0.075);
  border-radius: 5px;
  max-height: 150px;
  height: 150px;
}

.a-text-area:focus {
  border: solid 1px #6bd4c8 !important;
}

.text-area-bg{
  background-color: white;
  font-size: 1em;
}

@media screen and (max-width: 480px) {

  .a-bg-default {
    font-size: 1em;
  }

  .sem-bg .img-title {
    margin-top: -63px;
  }

  .sem-bg .borda {
    margin-top: 42px;
  }

  .m-btn {
    font-size: 22px !important;
    line-height: 29px !important;
    border-radius: 27px !important;

    width: 100%;
    padding: 10px 23px !important;
  }

  ul.actions li .numero {
    padding: 10px 14px !important;
  }

  .m-input {
    width: 100%;
  }
}

</style>
